"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Polyfills for IE. Important
require("./polyfills");
require("@babel/polyfill");
require('select2');
require('./../scss/sites/jaffa/jaffa.scss');
var index_1 = require("./../js/index");
var jaffa_1 = require("./../js/Sites/jaffa");
// Initialize scripts that are used in all websites
index_1.Init();
// Initialize Jaffa specific scripts
jaffa_1.InitJaffa();
// Import styles and scripts included in coreweb packages
index_1.ImportCoreweb();
// Initialize and mount react components
index_1.InitReact();
$(document).ready(function () {
    //Initialize select2 for Jaffa Contact form
    $('.Form__Element.FormSelection select').hide();
    $('.Form__Element.FormSelection select').select2({
        minimumResultsForSearch: Infinity,
        width: 'resolve',
        dropdownParent: $('.Form__MainBody')
    });
});
