"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function InitJaffa() {
    var navigationColor = '#002d73';
    window.addFullWidth = function () {
        if (!$(".content-wrapper").hasClass("has-bg-image")) {
            $(".o-navigation-wrapper").addClass("u-z-999 u-absolute u-full-width");
            $(".content-wrapper").addClass("has-bg-image");
        }
        $(".o-navigation").addClass("FFF");
        $(".o-mobile-menu").addClass("FFF");
        $("defs style").text(".logo-fill{fill:#FFF !important;}.cls-1{fill-rule:evenodd;}");
    };
    window.removeFullWidth = function () {
        if ($(".content-wrapper").hasClass("has-bg-image")) {
            $(".o-navigation-wrapper").removeClass("u-z-999 u-absolute u-full-width");
            $(".content-wrapper").removeClass("has-bg-image");
        }
        $(".o-navigation").removeClass("FFF");
        $(".o-mobile-menu").removeClass("FFF");
        $("defs style").text(".logo-fill{fill:" + navigationColor + " !important;}.cls-1{fill-rule:evenodd;}");
    };
    $('document').ready(function () {
        // Add relative class on ".content-wrapper"
        if ($(".o-history-section").length) {
            $(".content-wrapper").addClass("u-relative");
            // Add absolute class on ".o-history-section" when footer comes in view
            $(window).on('scroll', function () {
                if ($(window).scrollTop() >= $('.o-history-section').offset().top + $('.o-history-section').outerHeight() + (50) - window.innerHeight) {
                    $(".o-history-selected").addClass("o-history-selected--is-absolute");
                }
                else {
                    $(".o-history-selected").removeClass("o-history-selected--is-absolute");
                }
            });
        }
        ;
    });
}
exports.InitJaffa = InitJaffa;
